import React, { Component } from 'react';
import imgLogo from '../img/logo_xbz.png';
import { Link } from 'react-router-dom';
import PubSub from 'pubsub-js';

class navBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objPessoa2: {
        Id: '',
        CnpjCpf: '',
        CodigoAntigo: '',
        CodigoSistema: '',
        NomePessoaContato: '',
        RazaoSocialNome: 'EFETUE O LOGIN',
        NomeFantasia: '',
        InternetUrl: '',
        TelefonePrincipal: '',
        Email1: '',
        Email2: '',
        Email3: '',
        GrupoId: '',
        WebLogin: '',
        WebSenha: '',
        WebHabilitarAcessoPortal: '',
        WebExibePreco: '',
        WebExibeQuantidade: '',
        WebIgnoraRestricoes: '',
        WebToken: '',
      },

      objPessoa: '',
    };
  }

  componentDidMount() {
    PubSub.subscribe(
      'login-efetuado',
      function (p1, objPessoa) {
        this.setState({ objPessoa: objPessoa });
        console.log('NAVBAR RS = ' + this.state.objPessoa.RazaoSocialNome);
        console.log('NAVBAR ID = ' + this.state.objPessoa.Id);
      }.bind(this),
    );
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <Link className="navbar-brand" to="/">
          <img src={imgLogo} width="98" alt="" />{' '}
          <span className="sr-only">(current)</span>
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">


            {(() => {

              if (sessionStorage.WebConsultaPedidos === 'true') {
                return (
                  < li className="nav-item" >
                    <Link
                      className="nav-link"
                      to="/pedidos"
                      params={{ objPessoa: this.setState.objPessoa }}
                    >
                      PEDIDOS
                </Link>
                  </li>
                );
              }
            })()}


            {(() => {

              if (sessionStorage.WebConsultaProdutos === 'true') {
                return (
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/produtos"
                      params={{ objPessoa: this.setState.objPessoa }}
                    >
                      PRODUTOS
              </Link>
                  </li>

                );
              }
            })()}


          </ul>
        </div>
      </nav >
    );
  }
}

export default navBar;
