import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import image_not_available from '../img/image_not_available.png';

class ListaPedidosItens2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listaPedidos: [],
    };

    this.ordemDataEmissao = true;
    this.ordemNumeroPedido = true;
    this.ordemValor = true;
    this.ordemFinanceiro = true;
    this.ordemLogistico = true;
    this.ordemRetirada = true;
    this.ordemVolumes = true;

    this.ordemProdutoCodigo = true;
    this.ordemProdutoNome = true;
    this.ordemPedidoItemQuantidade = true;

    // _listaPedidos = [];
  }

  async carregaListaPedidos() {
    if (sessionStorage.idPessoa > 0) {
      await this.getPedidos(
        this.props.idPeriodoSelecionado,
        this.props.idStatusFinanceiroSelecionado,
        this.props.idStatusLogisticoSelecionado,
        sessionStorage.idTipoListagemSelecionada,
        this.props.idMostraEntregues,
      );
    } else {
      alert(
        'USUÁRIO NÃO AUTENTICADO. PARA REALIZAR A PESQUISA, PRIMEIRO INFORME SEU NOME DE USUÁRIO E SUA SENHA.',
      );
    }
  }

  getPedidos = async (
    _idPeriodoSelecionado,
    _idStatusFinanceiroSelecionado,
    _idStatusLogisticoSelecionado,
    _idTipoListagemSelecionada,
    _idMostraEntregues,
  ) => {
    const _listaPedidos = await new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      let url = new URL('https://api.minhaxbz.com.br:5001/api/ruiz/consultaPedidos',);
      url.searchParams.set('user', sessionStorage.minhaXbzUserName);
      url.searchParams.set('passwd', sessionStorage.minhaXbzPassword);
      url.searchParams.set('browserFingerPrint', sessionStorage.fingerPrint);
      url.searchParams.set('idPeriodoSelecionado', _idPeriodoSelecionado);
      url.searchParams.set('idStatusFinanceiroSelecionado', _idStatusFinanceiroSelecionado,);
      url.searchParams.set('idStatusLogisticoSelecionado', _idStatusLogisticoSelecionado,);
      url.searchParams.set('idTipoListagemSelecionada', _idTipoListagemSelecionada,);
      url.searchParams.set('idMostraEntregues', _idMostraEntregues);

      xhr.open('GET', url);
      xhr.send(null);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else if (xhr.status === 401) {
            alert(
              'USUÁRIO NÃO AUTENTICADO. PARA REALIZAR A PESQUISA, PRIMEIRO INFORME SEU NOME DE USUÁRIO E SUA SENHA.',
            );
          } else {
            reject('Erro');
          }
        }
      };
    })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return -999;
      });

    this.setState({ listaPedidos: _listaPedidos });
    //return _listaPedidos;
  };

  getSt = async (pessoa_id, produto_id) => {
    const obj = await new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      let url = new URL('https://api.minhaxbz.com.br:5001/api/ruiz/getSt');
      url.searchParams.set('produto_id', produto_id);
      url.searchParams.set('pessoa_id', pessoa_id);

      xhr.open('GET', url);
      xhr.send(null);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject('Erro');
          }
        }
      };
    })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log('error: ' + error);
        return -999;
      });

    return obj;
  };

  ordenaListaPorDataEmissao() {
    var listaOrdenada = this.state.listaPedidos.sort((a, b) =>
      (
        this.ordemDataEmissao
          ? a.DataEmissao > b.DataEmissao
          : a.DataEmissao < b.DataEmissao
      )
        ? 1
        : -1,
    );
    this.ordemDataEmissao = !this.ordemDataEmissao;
  }

  ordenaListaPorNumeroDoPedido() {
    var listaOrdenada = this.state.listaPedidos.sort((a, b) =>
      (
        this.ordemNumeroPedido
          ? a.PedidoNumero > b.PedidoNumero
          : a.PedidoNumero < b.PedidoNumero
      )
        ? 1
        : -1,
    );
    this.ordemNumeroPedido = !this.ordemNumeroPedido;
  }

  ordenaListaPorValorDoPedido() {
    var listaOrdenada = this.state.listaPedidos.sort((a, b) =>
      (this.ordemValor ? a.Valor > b.Valor : a.Valor < b.Valor) ? 1 : -1,
    );
    this.ordemValor = !this.ordemValor;
  }

  ordenaListaPorProdutoCodigo() {
    var listaOrdenada = this.state.listaPedidos.sort((a, b) =>
      (this.ordemProdutoCodigo ? a.ProdutoCodigoComposto > b.ProdutoCodigoComposto : a.ProdutoCodigoComposto < b.ProdutoCodigoComposto) ? 1 : -1,
    );
    this.ordemProdutoCodigo = !this.ordemProdutoCodigo;
  }

  ordenaListaPorProdutoNome() {
    var listaOrdenada = this.state.listaPedidos.sort((a, b) =>
      (this.ordemProdutoNome ? a.ProdutoNome > b.ProdutoNome : a.ProdutoNome < b.ProdutoNome) ? 1 : -1,
    );
    this.ordemProdutoNome = !this.ordemProdutoNome;
  }

  ordenaListaPorPedidoItemQuantidade() {
    var listaOrdenada = this.state.listaPedidos.sort((a, b) =>
      (this.ordemPedidoItemQuantidade ? a.PedidoItemQuantidade > b.PedidoItemQuantidade : a.PedidoItemQuantidade < b.PedidoItemQuantidade) ? 1 : -1,
    );
    this.ordemPedidoItemQuantidade = !this.ordemPedidoItemQuantidade;
  }


  ordernaListaPorStatusFinanceiro() {
    var listaOrdenada = this.state.listaPedidos.sort((a, b) =>
      (
        this.ordemFinanceiro
          ? a.LoteDebitoStatusCliente > b.LoteDebitoStatusCliente
          : a.LoteDebitoStatusCliente < b.LoteDebitoStatusCliente
      )
        ? 1
        : -1,
    );
    this.ordemFinanceiro = !this.ordemFinanceiro;
  }

  ordernaListaPorStatusLogistico() {
    var listaOrdenada = this.state.listaPedidos.sort((a, b) =>
      (
        this.ordemLogistico
          ? a.StatusLogistico > b.StatusLogistico
          : a.StatusLogistico < b.StatusLogistico
      )
        ? 1
        : -1,
    );
    this.ordemLogistico = !this.ordemLogistico;
  }

  ordernaListaPorRetirada() {
    var listaOrdenada = this.state.listaPedidos.sort((a, b) =>
      (
        this.ordemRetirada
          ? a.ResponsavelPelaRetirada > b.ResponsavelPelaRetirada
          : a.ResponsavelPelaRetirada < b.ResponsavelPelaRetirada
      )
        ? 1
        : -1,
    );
    this.ordemRetirada = !this.ordemRetirada;
  }

  ordernaListaPorQtdVolumes() {
    var listaOrdenada = this.state.listaPedidos.sort((a, b) =>
      (this.ordemVolumes ? a.QtdVolumes > b.QtdVolumes : a.QtdVolumes < b.QtdVolumes) ? 1 : -1,
    );
    this.ordemVolumes = !this.ordemVolumes;
  }

  componentDidMount() {
    PubSub.subscribe(
      'atualiza-lista-pedidos-itens-2',
      function () {
        this.carregaListaPedidos();
      }.bind(this),
    );
  }

  render() {
    return (
      <div className="table-responsive table-condensed">
        <div>
          <table className="table">
            <thead>
              <tr>
                {/*<th scope="col">IMAGEM</th>*/}
                <th scope="col">
                  <a
                    href="#"
                    onClick={this.ordenaListaPorDataEmissao.bind(this)}
                  >
                    EMISSÃO
                  </a>
                </th>
                <th scope="col">
                  <a
                    href="#"
                    onClick={this.ordenaListaPorNumeroDoPedido.bind(this)}
                  >
                    PEDIDO
                  </a>
                </th>
                <th scope="col">
                  <a
                    href="#"
                    onClick={this.ordenaListaPorValorDoPedido.bind(this)}
                  >
                    VALOR (R$)
                  </a>
                </th>

                < th scope="col" >
                  <a href="#" onClick={this.ordenaListaPorProdutoCodigo.bind(this)}>
                    PRODUTO CÓD.
              </a>
                </th>
                <th scope="col">
                  <a href="#" onClick={this.ordenaListaPorProdutoNome.bind(this)}>
                    PRODUTO NOME
              </a>
                </th>

                <th scope="col">
                  <a href="#" onClick={this.ordenaListaPorPedidoItemQuantidade.bind(this)}>
                    QTD.
              </a>
                </th>


                <th scope="col">
                  <a
                    href="#"
                    onClick={this.ordernaListaPorStatusFinanceiro.bind(this)}
                  >
                    STATUS DO PAGAMENTO
                  </a>
                </th>
                <th scope="col">
                  <a
                    href="#"
                    onClick={this.ordernaListaPorStatusLogistico.bind(this)}
                  >
                    SITUAÇÃO
                  </a>
                </th>
                <th scope="col">
                  <a href="#" onClick={this.ordernaListaPorRetirada.bind(this)}>
                    RESPONSÁVEL PELA RETIRADA
                  </a>
                </th>

                <th scope="col">
                  <a
                    href="#"
                    onClick={this.ordernaListaPorQtdVolumes.bind(this)}
                  >
                    QTD. DE VOLUMES
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.listaPedidos.map((pedido) => (
                <tr
                  key={pedido.Id} bgcolor={
                    pedido.IdLoteDebitoStatus < 2 ?
                      'MistyRose' : pedido.ProntoParaRetirada === true ?
                        'HoneyDew' : pedido.IdLoteDebitoStatus >= 2 && pedido.IdStatus >= 4 && pedido.IdStatus <= 6 ?
                          'LightYellow' : 'White'
                  }
                >
                  <td align="left">{pedido.DataEmissaoString}</td>

                  <td align="left">{pedido.Numero}</td>

                  <td align="Right">{pedido.PrecoVendaFormatado}</td>


                  <td align="left">
                    {pedido.ProdutoCodigoComposto}
                  </td>

                  <td align="left">
                    {pedido.ProdutoNome}
                  </td>

                  <td align="Right">
                    {pedido.PedidoItemQuantidade}
                  </td>

                  <td align="left">{pedido.LoteDebitoStatusCliente}</td>

                  <td align="left">{pedido.StatusLogistico}</td>

                  <td align="left">{pedido.ResponsavelRetirada}</td>

                  <td align="right">{pedido.QtdVolumes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div >
    );
  }
}

export default ListaPedidosItens2;
