import React, { Component } from 'react';
import ListaProdutos from './ListaProdutos';
import PubSub from 'pubsub-js';

class PageProdutos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listaTeste: [
        {
          produtoId: 14,
          produtoCodigo: 'COD-23100',
          produtoNome: 'CANETA MODELO X',
          produtoPreco: '0,89',
          produtoDisponivel: 'SIM',
        },
        {
          produtoId: 33,
          produtoCodigo: 'XYT-AB33DDG2',
          produtoNome: 'PRODUTO ALEATORIO X3X',
          produtoPreco: '4,59',
          produtoDisponivel: 'NÃO',
        },
        {
          produtoId: 87,
          produtoCodigo: 'ABC-OOP98-FFF',
          produtoNome: 'AGENDA MILENAR 01',
          produtoPreco: '94,00',
          produtoDisponivel: 'SIM',
        },
      ],

      listaProdutoTipoWeb: [{ Id: '' }, { Tipo: '' }],

      listaProdutoSubTipoWeb: [{ Id: '' }, { TipoId: '' }, { SubTipo: '' }],

      listaCores: [{ Id: '' }, { NomeCor: '' }, { SiglaCor: '' }],

      listaTemas: [{ Id: '' }, { Tema: '' }],

      listaMateriais: [{ Id: '' }, { Material: '' }],

      listaFiltroOpcoes: [
        { Id: 0, Opcao: 'MOSTRAR TODOS' },
        { Id: 1, Opcao: 'OCULTAR PRODUTOS SOB CONSULTA' },
      ],

      txtBusca: '',
      quantidade: '0',
      idTipoSelecionado: '0',
      idSubTipoSelecionado: '0',
      idCorSelecionada: '0',
      idTemaSelecionado: '0',
      idMaterialSelecionado: '0',
      idFiltroOpcaoSelecionada: '0',
      objPessoa: '',
    };

    this.setBusca = this.setBusca.bind(this);
    this.setFiltro = this.setFiltro.bind(this);
    this.setQuantidade = this.setQuantidade.bind(this);
    this.setTipo = this.setTipo.bind(this);
    this.setSubTipo = this.setSubTipo.bind(this);
    this.setCores = this.setCores.bind(this);
    this.setTema = this.setTema.bind(this);
    this.setMaterial = this.setMaterial.bind(this);
  }

  setTipo(event) {
    this.setState({ idTipoSelecionado: event.target.value });
    //console.log("Tipo no event: " + event.target.value);
    //console.log("Tipo no state: " + this.state.idTipoSelecionado);
    this.carregaSubTipos(event.target.value);
  }

  setSubTipo(event) {
    this.setState({ idSubTipoSelecionado: event.target.value });
    //console.log("Tipo no state: " + this.state.idTipoSelecionado);
    //console.log("SubTipo no event: " + event.target.value);
    //console.log("SubTipo no state: " + this.state.idSubTipoSelecionado);
  }

  setCores(event) {
    this.setState({ idCorSelecionada: event.target.value });
    //console.log("Cor no state: " + this.state.idCorSelecionada);
    //console.log("Cor no event: " + event.target.value);
  }

  setTema(event) {
    this.setState({ idTemaSelecionado: event.target.value });
    //console.log("Cor no state: " + this.state.idCorSelecionada);
    //console.log("Cor no event: " + event.target.value);
  }

  setMaterial(event) {
    this.setState({ idMaterialSelecionado: event.target.value });
    //console.log("Cor no state: " + this.state.idCorSelecionada);
    //console.log("Cor no event: " + event.target.value);
  }

  setBusca(event) {
    this.setState({ txtBusca: event.target.value });
    //console.log("txtBusca no state: " + this.state.txtBusca);
    //console.log("txtBusca no event: " + event.target.value);
  }

  setFiltro(event) {
    this.setState({ idFiltroOpcaoSelecionada: event.target.value });
    //console.log("filtro no state: " + this.state.idFiltroOpcaoSelecionada);
    //console.log("filtro no event: " + event.target.value);
  }

  setQuantidade(event) {
    this.setState({ quantidade: event.target.value });
    //console.log("Quantidade no state: " + this.state.quantidade);
    //console.log("Quantidade no event: " + event.target.value);
  }

  async carregaSubTipos(tipo_id) {
    this.getSubTipos(tipo_id);
  }

  componentDidMount() {
    PubSub.subscribe(
      'login-efetuado',
      function (p1, objPessoa) {
        if (objPessoa.Id > 0) {
          this.setState({ objPessoa: objPessoa });
          console.log(
            'OBJ PESSOA pAGEpRODUTOS DID MOUNT: ' + objPessoa.RazaoSocialNome,
          );
        } else {
          window.location.replace('/');
        }
      }.bind(this),
    );

    this.carregaCombos();
  }

  async carregaCombos() {
    await this.getTipos();
    await this.getCores();
    await this.getTemas();
    await this.getMateriais();
  }

  getTipos = async (user, pass, fp) => {
    const _listaTipos = await new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      let url = new URL(
        'https://api.minhaxbz.com.br:5001/api/ruiz/produtosTiposWeb',
      );
      //url.searchParams.set('user', user);
      //url.searchParams.set('passwd', pass);
      //url.searchParams.set('browserFingerPrint', fp);

      xhr.open('GET', url);
      xhr.send(null);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject('Erro');
          }
        }
      };
    })
      .then(function (response) {
        //console.log("r1: " + response);
        //console.log("r2: " + response.Id);
        return response;
      })
      .catch(function (error) {
        //console.log("error: " + error);

        return -999;
      });

    this.setState({ listaProdutoTipoWeb: _listaTipos });
    //console.log("carregou lista de TIPOS: " + _listaTipos);

    return _listaTipos;
  };

  getSubTipos = async (_idTipo = 2) => {
    const _listaSubTipos = await new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      let url = new URL(
        'https://api.minhaxbz.com.br:5001/api/ruiz/produtosSubTiposWeb',
      );
      url.searchParams.set('IdProdutoTipoWeb', _idTipo);
      //url.searchParams.set('passwd', pass);
      //url.searchParams.set('browserFingerPrint', fp);

      xhr.open('GET', url);
      xhr.send(null);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject('Erro');
          }
        }
      };
    })
      .then(function (response) {
        //console.log("r1eee: " + response);
        //console.log("r2eee: " + response.Id);
        return response;
      })
      .catch(function (error) {
        //console.log("error: " + error);
        return -999;
      });

    this.setState({ listaProdutoSubTipoWeb: _listaSubTipos });
    //console.log(_listaSubTipos);

    return _listaSubTipos;
  };

  getCores = async () => {
    const _listaCores = await new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      let url = new URL(
        'https://api.minhaxbz.com.br:5001/api/ruiz/produtosCores',
      );
      //url.searchParams.set('IdProdutoTipoWeb', _idTipo);
      //url.searchParams.set('passwd', pass);
      //url.searchParams.set('browserFingerPrint', fp);

      xhr.open('GET', url);
      xhr.send(null);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject('Erro');
          }
        }
      };
    })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return -999;
      });

    this.setState({ listaCores: _listaCores });
    //console.log("lista de cores " + _listaCores);

    return _listaCores;
  };

  getTemas = async () => {
    const _listaTemas = await new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      let url = new URL(
        'https://api.minhaxbz.com.br:5001/api/ruiz/produtosTemas',
      );
      //url.searchParams.set('IdProdutoTipoWeb', _idTipo);
      //url.searchParams.set('passwd', pass);
      //url.searchParams.set('browserFingerPrint', fp);

      xhr.open('GET', url);
      xhr.send(null);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject('Erro');
          }
        }
      };
    })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return -999;
      });

    this.setState({ listaTemas: _listaTemas });
    //console.log("lista de cores " + _listaCores);

    return _listaTemas;
  };

  getMateriais = async () => {
    const _listaMateriais = await new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      let url = new URL(
        'https://api.minhaxbz.com.br:5001/api/ruiz/produtosMateriais',
      );
      //url.searchParams.set('IdProdutoTipoWeb', _idTipo);
      //url.searchParams.set('passwd', pass);
      //url.searchParams.set('browserFingerPrint', fp);

      xhr.open('GET', url);
      xhr.send(null);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject('Erro');
          }
        }
      };
    })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return -999;
      });

    this.setState({ listaMateriais: _listaMateriais });

    return _listaMateriais;
  };

  submitBusca(event) {
    if (sessionStorage.WebConsultaProdutos === 'true') {
      event.preventDefault();
      PubSub.publish('atualiza-lista');
    } else {
      alert(
        'A CONSULTA DE PRODUTOS NÃO ESTÁ DISPONÍVEL. ENTRE EM CONTATO COM O [SAC] PARA MAIS INFORMAÇÕES',
      );
    }
  }
  render() {
    return (
      <div className="card" style={{ margin: '10px 0px 10px 0px' }}>
        <div className="container-fluid" style={{ margin: '15px 0px 5px 0px' }}>
          <h4 align="center">PESQUISA DE PRODUTOS EM ESTOQUE</h4>
        </div>

        <div
          className="container-fluid"
          style={{ margin: '10px 0px 10px 0px' }}
        >
          <form onSubmit={this.submitBusca}>
            <div className="form-row">
              <h4 align="center">FILTROS OBRIGATÓRIOS</h4>
            </div>

            <div className="form-row">
              <div
                className="form-group col-md-4"
                data-toggle="tooltip"
                title="Um campo aberto para facilitar sua busca. Digite parte do nome do produto que você procura."
              >
                <input
                  id="busca"
                  type="text"
                  placeholder="BUSCA (CÓDIGO, NOME, ETC...)"
                  className="form-control"
                  value={this.state.txtBusca}
                  onChange={this.setBusca}
                />
              </div>

              <div
                className="form-group col-md-2"
                data-toggle="tooltip"
                title="Defina a quantidade que você quer consultar"
              >
                <input
                  id="qtd"
                  type="number"
                  placeholder="QTD."
                  min="0"
                  className="form-control"
                  value={this.state.quantidade}
                  onChange={this.setQuantidade}
                />
              </div>

              <div
                className="form-group col-md-3"
                data-toggle="tooltip"
                title="Escolha se você quer ver TODOS OS PRODUTOS, ou prefere OCULTAR DA SUA LISTA OS ITENS INDISPONIVEIS"
              >
                <select
                  id="filtro"
                  className="form-control"
                  value={this.state.idFiltroOpcaoSelecionada}
                  onChange={this.setFiltro}
                >
                  {this.state.listaFiltroOpcoes.map((itemf, i) => (
                    <option key={i} value={itemf.Id}>
                      {itemf.Opcao}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-3">
                <button type="submit" className="btn btn-primary mb-3">
                  BUSCAR
                </button>
              </div>
            </div>

            <div className="form-row">
              <h6 align="center"> </h6>
            </div>

            <div className="form-row">
              <h6 align="center">FILTROS OPCIONAIS</h6>
            </div>

            <div className="form-row">
              <div className="form-group col-md-3">
                <select
                  id="cor"
                  className="form-control"
                  value={this.state.idCorSelecionada}
                  onChange={this.setCores}
                >
                  <option value="0">COR...</option>
                  {this.state.listaCores.map((item_c, i) => (
                    <option key={i} value={item_c.Id}>
                      {item_c.NomeCor}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-2 ">
                <select
                  id="selectTipo"
                  className="form-control"
                  value={this.state.idTipoSelecionado}
                  onChange={this.setTipo}
                >
                  <option value="0">TIPO...</option>
                  {this.state.listaProdutoTipoWeb.map((item_t, i) => (
                    <option key={i} value={item_t.Id}>
                      {item_t.Tipo}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-2">
                <select
                  id="selectSubTipo"
                  className="form-control"
                  value={this.state.idSubTipoSelecionado}
                  onChange={this.setSubTipo}
                >
                  <option value="0">SUBTIPO...</option>
                  {this.state.listaProdutoSubTipoWeb.map((item_s, i) => (
                    <option key={i} value={item_s.Id}>
                      {item_s.SubTipo}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-2">
                <select
                  id="material"
                  className="form-control"
                  value={this.state.idMaterialSelecionado}
                  onChange={this.setMaterial}
                >
                  <option value="0">MATERIAL PRINCIPAL...</option>
                  {this.state.listaMateriais.map((item_m, i) => (
                    <option key={i} value={item_m.Id}>
                      {item_m.Material}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-3">
                <select
                  id="tema"
                  className="form-control"
                  value={this.state.idTemaSelecionado}
                  onChange={this.setTema}
                >
                  <option value="0">TEMA...</option>
                  {this.state.listaTemas.map((item_t, i) => (
                    <option key={i} value={item_t.Id}>
                      {item_t.Tema}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>

          <div
            className="container-fluid"
            style={{ margin: '15px 0px 5px 0px' }}
          >
            <ListaProdutos
              tipo_id={this.state.idTipoSelecionado}
              subtipo_id={this.state.idSubTipoSelecionado}
              busca={this.state.txtBusca}
              cor_id={this.state.idCorSelecionada}
              tema_id={this.state.idTemaSelecionado}
              filtro={this.state.idFiltroOpcaoSelecionada}
              quantidade={this.state.quantidade}
              objPessoa={this.state.objPessoa}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PageProdutos;
