import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import image_not_available from '../img/image_not_available.png';

class ListaProdutos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listaProdutos: [],
      pessoa_id: '0',
      estado2: '',
      fiscal: {
        PrecoVenda: '',
        PrecoVendaFormatado: '',
        PrecoBaseCalculo: '',
        PrecoBaseCalculoFormatado: '',
        ValorStCalculado: '',
        ValorStFormatado: '',
        PercentualIpi: '',
        ValorIpiCalculado: '',
        ValorIpiFormatado: '',
        Ncm: '',
      },
    };

    this.ordemCodigo = true;
    this.ordemNome = true;
    this.ordemVendaMinima = true;
    this.ordemPreco = true;
    this.ordemDisponivel = true;
  }

  async carregaLista() {
    if (sessionStorage.idPessoa > 0) {
      if (this.props.quantidade < 1) {
        alert('INFORME A QUANTIDADE QUE VOCÊ DESEJA CONSULTAR');
      }
      else {
        await this.getProdutos(
          this.props.objPessoa.Id,
          this.props.tipo_id,
          this.props.subtipo_id,
          this.props.cor_id,
          this.props.tema_id,
          this.props.quantidade,
          this.props.filtro,
          this.props.busca,
        );
      }
    }
    else {
      alert('USUÁRIO NÃO AUTENTICADO. PARA REALIZAR A PESQUISA, PRIMEIRO INFORME SEU NOME DE USUÁRIO E SUA SENHA.');
    }
  }

  getProdutos = async (
    _pessoa_id,
    _web_tipo_id,
    _web_subtipo_id,
    _cor_id,
    _tema_id,
    _quantidade,
    _filtro,
    _busca,
  ) => {
    const _listaProdutos = await new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      let url = new URL(
        'https://api.minhaxbz.com.br:5001/api/ruiz/consultaEstoque',
      );
      url.searchParams.set('user', sessionStorage.minhaXbzUserName);
      url.searchParams.set('passwd', sessionStorage.minhaXbzPassword);
      url.searchParams.set('webToken', sessionStorage.XbzWebToken);
      url.searchParams.set('browserId', sessionStorage.fingerPrint);
      url.searchParams.set('web_tipo_id', _web_tipo_id);
      url.searchParams.set('web_sub_tipo_id', _web_subtipo_id);
      url.searchParams.set('cor_id', _cor_id);
      url.searchParams.set('tema_id', _tema_id);
      url.searchParams.set('quantidade', _quantidade);
      url.searchParams.set('filtro_id', _filtro);
      url.searchParams.set('busca', _busca);

      xhr.open('GET', url);
      xhr.send(null);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject('Erro');
          }
        }
      };
    })
      .then(function (response) {
        //console.log("listaProdutosResponse: " + response);
        //console.log("listaProdutosResponde.Id: " + response.Id);
        return response;
      })
      .catch(function (error) {
        // console.log("error: " + error);

        return -999;
      });

    this.setState({ listaProdutos: _listaProdutos });
    //console.log(_listaProdutos);

    return _listaProdutos;
  };

  getSt = async (pessoa_id, produto_id) => {
    const obj = await new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      let url = new URL('https://api.minhaxbz.com.br:5001/api/ruiz/getSt');
      url.searchParams.set('produto_id', produto_id);
      url.searchParams.set('pessoa_id', pessoa_id);

      xhr.open('GET', url);
      xhr.send(null);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject('Erro');
          }
        }
      };
    })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log('error: ' + error);
        return -999;
      });

    return obj;
  };

  ordenaListaPorPreco() {
    var listaOrdenada = this.state.listaProdutos.sort((a, b) =>
      (
        this.ordemPreco
          ? a.PrecoVenda > b.PrecoVenda
          : a.PrecoVenda < b.PrecoVenda
      )
        ? 1
        : -1,
    );
    this.ordemPreco = !this.ordemPreco;
  }

  ordenaListaPorCodigo() {
    var listaOrdenada = this.state.listaProdutos.sort((a, b) =>
      (
        this.ordemCodigo
          ? a.CodigoComposto > b.CodigoComposto
          : a.CodigoComposto < b.CodigoComposto
      )
        ? 1
        : -1,
    );
    this.ordemCodigo = !this.ordemCodigo;
  }

  ordenaListaPorNome() {
    var listaOrdenada = this.state.listaProdutos.sort((a, b) =>
      (this.ordemNome ? a.Nome > b.Nome : a.Nome < b.Nome) ? 1 : -1,
    );
    this.ordemNome = !this.ordemNome;
  }

  ordenaListaPorDisponibilidade() {
    var listaOrdenada = this.state.listaProdutos.sort((a, b) =>
      (
        this.ordemDisponivel
          ? a.Disponivel > b.Disponivel
          : a.Disponivel < b.Disponivel
      )
        ? 1
        : -1,
    );
    this.ordemDisponivel = !this.ordemDisponivel;
  }
  ordenaListaPorQuantidadeMinima() {
    var listaOrdenada = this.state.listaProdutos.sort((a, b) =>
      (
        this.ordemVendaMinma
          ? a.VendaMinima > b.VendaMinima
          : a.VendaMinima < b.VendaMinima
      )
        ? 1
        : -1,
    );
    this.ordemVendaMinma = !this.ordemVendaMinma;
  }

  componentDidMount() {
    PubSub.subscribe(
      'atualiza-lista',
      function (p1, objPessoa) {
        this.setState({ objPessoa: objPessoa });
        this.carregaLista();
      }.bind(this),
    );
  }

  exibeSt = async () => {
    {
      /*this.setState({fiscal: await this.getSt(this.state.pessoa_id, this.state.produto_id)});*/
    }
    console.log(this.state.fiscal);
    console.log(this.state.fiscal.Ncm);
    console.log(this.state.pessoa_id);
    console.log(this.state.produto_id);
  };

  exibeSt3 = async (produto) => {
    this.setState({
      fiscal: await this.getSt(sessionStorage.idPessoa, produto.IdProduto),
    });
    console.log(sessionStorage.idPessoa);
    console.log(this.state.fiscal);
    console.log(this.state.fiscal.Ncm);
    console.log(this.state.pessoa_id);
    console.log(this.state.produto_id);

    alert(
      'NOME:  ' +
      produto.Nome +
      '\nCÓDIGO:  ' +
      produto.CodigoComposto +
      '\nNCM:  ' +
      this.state.fiscal.Ncm +
      '\nPREÇO:  R$ ' +
      this.state.fiscal.PrecoVendaFormatado +
      '\nST:   R$ ' +
      this.state.fiscal.ValorStFormatado,
    );
  };

  render() {
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">IMAGEM</th>
              <th scope="col">
                <a href="#" onClick={this.ordenaListaPorCodigo.bind(this)}>
                  CÓDIGO
                </a>
              </th>
              <th scope="col">
                <a href="#" onClick={this.ordenaListaPorNome.bind(this)}>
                  NOME
                </a>
              </th>
              {/*<th scope="col" data-toggle="tooltip" title="Pedidos somente acima desta quantidade"><a href="#" onClick={this.ordenaListaPorQuantidadeMinima.bind(this)}>PEDIDO MÍNIMO</a></th>*/}
              <th scope="col">
                <a href="#" onClick={this.ordenaListaPorPreco.bind(this)}>
                  PREÇO (R$)
                </a>
              </th>
              <th
                scope="col"
                data-toggle="tooltip"
                //title="[SIM = Temos a quantidade] [NÃO = Não temos essa quantidade a pronta entrega]  [CONSULTE = Verifique a disponibilidade com seu vendedor]"
              >
                <a
                  href="#"
                  onClick={this.ordenaListaPorDisponibilidade.bind(this)}
                >
                  DISPONÍVEL?
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.listaProdutos.map((produto) => (
              <tr
                key={produto.IdProduto}
                bgcolor={
                  produto.Disponivel === 'SIM' ? 'MediumSpringGreen' : produto.Disponivel === 'INDISPONÍVEL' ? 'Thistle' : produto.Disponivel.includes('SOMENTE') ? 'SkyBlue' : 'LightYellow'
                }
              >
                <td>
                  {' '}
                  <img
                    src={
                      produto.ImageLink.length < 10
                        ? image_not_available
                        : produto.ImageLink
                    }
                    alt=""
                    height="60"
                  />
                </td>
                <th scope="cod">
                  {' '}
                  {produto.SiteLink.length < 10 ? (
                    produto.CodigoComposto
                  ) : (
                      <a
                        href={produto.SiteLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {produto.CodigoComposto}
                      </a>
                    )}{' '}
                </th>
                <td>{produto.Nome}</td>
                {/*<td align="center">{(produto.VendaMinima)}</td>*/}
                {/* <td align="center">{(produto.PrecoVendaFormatado)}</td>*/}

                <td
                  align="center"
                  data-toggle="tooltip"
                  title="Clique para saber o valor da ST"
                  onClick={() => this.exibeSt3(produto)}
                >
                  {produto.PrecoVendaFormatado}
                </td>

                <td align="left">{produto.Disponivel}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ListaProdutos;
