import {Component} from 'react';

// devolve um objeto referente ao usuário/cliente que se autenticou
// alem dos dados da empresa (nome, cod, cnpj...), ele devolve um token
// e o armazena no localStorage para futuro uso.
// no backend, esse token será validado e o front 
// definirá se outro precisará ou não ser gerado.
// 21/07/2019

class TokenAutenticacao extends Component {

    getToken = async(user, pass, fp) => {
        const objCliente = await new Promise(function(resolve, reject){
            var xhr = new XMLHttpRequest();
            let url = new URL('https://api.minhaxbz.com.br:5001/api/ruiz/getToken');
            url.searchParams.set('user', user);
            url.searchParams.set('passwd', pass);
            url.searchParams.set('browserFingerPrint', fp);

            xhr.open('GET', url);
            xhr.send(null);
    
            xhr.onreadystatechange = function(){
                if (xhr.readyState === 4){
                    if (xhr.status === 200){
                        resolve(JSON.parse(xhr.responseText));
                    }else{
                        console.log("A");
                        reject('Erro');
                    }
                }
            }
        }).then(function(response){
                //console.log("r1: " + response);
                //console.log("r2: " + response.RazaoSocialNome);
                console.log("B");
                return response;
            })
            .catch(function(error){
                console.log("error: " + error);
                
                return -999;
            });
            
            sessionStorage.setItem("XbzWebToken", objCliente.WebToken);
            sessionStorage.setItem("idPessoa", objCliente.Id);
            //this.setState({webToken: localStorage.getItem("XbzWebToken"), 
            //                msgLogin: objCliente===-999 ? "ERRO DE CONEXÃO COM O SERVIDOR" : objCliente.Id > 0 ? objCliente.RazaoSocialNome : "Efetue o login..."});

            

            return objCliente;
    }

};

export default TokenAutenticacao;