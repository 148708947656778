import React from 'react';
import BroserId from '../myFunc/BroserId';
import TokenAutenticacao from '../myFunc/TokenAutenticacao';
import PubSub from 'pubsub-js'

export default class FormLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.username,
            password: this.props.password,
            msgLogin: '',
            fingerPrint: '',
            objPessoa: {
                Id: '',
                CnpjCpf: '',
                CodigoAntigo: '',
                CodigoSistema: '',
                NomePessoaContato: '',
                RazaoSocialNome: 'EFETUE O LOGIN',
                NomeFantasia: '',
                InternetUrl: '',
                TelefonePrincipal: '',
                Email1: '',
                Email2: '',
                Email3: '',
                GrupoId: '',
                WebLogin: '',
                WebSenha: '',
                WebHabilitarAcessoPortal: '',
                WebExibePreco: '',
                WebExibeQuantidade: '',
                WebIgnoraRestricoes: '',

                WebConsultaPedidos: '',
                WebConsultaProdutos: '',
                WebExibePrecosPedidos: '',

                WebToken: ''
            }
        };

        this.setUsername = this.setUsername.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.submitLogin = this.submitLogin.bind(this);

        sessionStorage.setItem("XbzWebToken", "");
        sessionStorage.setItem("idPessoa", 0);
        sessionStorage.setItem("fingerPrint", "");
        sessionStorage.setItem("minhaXbzUserName", "");
        sessionStorage.setItem("minhaXbzPassword", "");
    }

    setUsername(event) { this.setState({ username: event.target.value }); }
    setPassword(event) { this.setState({ password: event.target.value }); }

    submitLogin(event) {
        event.preventDefault();
        this.autenticacao();
    }

    autenticacao = async () => {
        // obter o fingerPrint (Id do browser)
        var b = new BroserId();
        this.setState({ fingerPrint: await b.getFingerPrint() });
        sessionStorage.setItem("fingerPrint", this.state.fingerPrint);


        // envia dados de usuario e senha e obter o token
        var t = new TokenAutenticacao();
        this.setState({ objPessoa: await t.getToken(this.state.username, this.state.password, this.state.fingerPrint) });

        if (this.state.objPessoa.Id === 0) {
            alert("USUÁRIO OU SENHA INVÁLIDOS. \n\nTENTE NOVAMENTE.")
            sessionStorage.setItem("minhaXbzUserName", "");
            sessionStorage.setItem("minhaXbzPassword", "");
        }
        else {
            sessionStorage.setItem("minhaXbzUserName", this.state.username);
            sessionStorage.setItem("minhaXbzPassword", this.state.password);
        }

        sessionStorage.setItem("WebConsultaPedidos", this.state.objPessoa.WebConsultaPedidos);
        sessionStorage.setItem("WebConsultaProdutos", this.state.objPessoa.WebConsultaProdutos);

        PubSub.publish('login-efetuado', this.state.objPessoa);
    }

    componentDidMount() {
        console.log("componentDidMount do FormLogin");
    }


    render() {
        return (

            <div className="card" style={{ margin: '10px 0px 0px 0px' }}>

                <div className="container">
                    <div className="row justify-content-md-left align-middle">
                        <div className="col col-lg-5 col-md-5 align-middle">

                            <form onSubmit={this.submitLogin}>
                                <div className="form-row" style={{ margin: '20px 10px 0px 0px' }}>

                                    <div className="form-group col-5 col-md-5" >
                                        <input className="form-control mr-sm-2" type="text" autoComplete="username" value={this.state.username} onChange={this.setUsername} placeholder="Usuário" aria-label="Login" />
                                    </div>

                                    <div className="form-group col-5 col-md-5" >
                                        <input className="form-control mr-sm-2" type="password" autoComplete="current-password" value={this.state.password} onChange={this.setPassword} placeholder="Senha" aria-label="Senha" />
                                    </div>

                                    <div className="form-group col-1 col-md-1">
                                        <button className="btn btn-primary " type="submit">Login</button>
                                    </div>

                                </div>
                            </form>
                        </div>

                        <div className="col-md-auto align-middle" >
                            {/*<div className="loginText h5" >{this.state.objPessoa.Id === 0  ? "EFETUE O LOGIN" : this.state.objPessoa.RazaoSocialNome}</div>*/}
                            <div className="loginText h5" >{this.state.objPessoa.RazaoSocialNome}</div>
                            <div className="token"><small>UserToken: {this.state.objPessoa.WebToken === null ? "?" : this.state.objPessoa.WebToken}</small></div>
                            <div className="fp"><small>BrowserId: {this.state.fingerPrint === null ? "?" : this.state.fingerPrint}</small></div>
                        </div>

                    </div>
                </div>

            </div>

        );
    }
}

