import {Component} from 'react';
import Fingerprint2 from 'fingerprintjs2';

// gera um hash para cada browser em que o sistema for acessado.
// esse hash ficará armazenado no sistema, e será autorizado, para que 
// o cliente possa fazer requisições a api
// foi a unica maneira encontrada para gerar uma identificação individual para cada dispositivo
// 21/07/2019

class BroserId extends Component {

  getFingerPrint = async() => {
    const r = await new Promise(function(resolve, reject){
        var options = {}
        Fingerprint2.getPromise(options).then(function (components) {
            var values = components.map(function (component) { return component.value })
            var murmur = Fingerprint2.x64hash128(values.join(''), 31)
            resolve(murmur);
        })
    }).then(function(response){
            console.log("Browser FingerPrint Hash: " + response);
            return response;
        })
        .catch(function(error){
            console.log("fingerPrint Error: " + error);
            return error;
        });

        return r;
  } 

};

export default BroserId;