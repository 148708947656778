import React, { Component } from 'react';
import ListaPedidos2 from './ListaPedidos2';
import ListaPedidosItens2 from './ListaPedidosItens2';
import PubSub from 'pubsub-js';

class PageConsultaPedidos2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idPeriodoSelecionado: '1',
      listaPeriodo: [
        { Id: 0, Opcao: '1 MÊS' },
        { Id: 1, Opcao: '2 MESES' },
        { Id: 2, Opcao: '3 MESES' },
        { Id: 3, Opcao: '6 MESES' },
        { Id: 4, Opcao: '12 MESES' },
      ],

      idStatusLogisticoSelecionado: '1',
      listaStatusLogistico: [
        { Id: 1, Opcao: 'TODOS' },
        { Id: 2, Opcao: 'PEDIDO EMITIDO' },
        { Id: 3, Opcao: 'EM PROCESSO DE SEPARAÇÃO' },
        { Id: 4, Opcao: 'PRONTO PARA RETIRADA/ENVIO' },
        { Id: 5, Opcao: 'CANCELADO' },
        { Id: 6, Opcao: 'RETIRADO/ENVIADO' },
      ],

      idStatusFinanceiroSelecionado: '1',
      listaStatusFinanceiro: [
        { Id: 1, Opcao: 'TODOS' },
        { Id: 2, Opcao: 'SOMENTE PAGOS' },
        { Id: 3, Opcao: 'SOMENTE AGUARDANDO PAGAMENTO' },
      ],

      idMostraEntregues: '2',
      listaMostraEntregues: [
        { Id: 1, Opcao: 'MOSTRAR TODOS' },
        { Id: 2, Opcao: 'OCULTAR FINALIZADOS/ENTREGUES' },
        { Id: 3, Opcao: 'SOMENTE FINALIZADOS/ENTREGUES' },
      ],

      idTipoListagemSelecionada: '1',
      listaTipoListagem: [
        { Id: 1, Opcao: 'LISTA DE PEDIDOS' },
        { Id: 2, Opcao: 'LISTA DE ITENS' },
      ],

      objPessoa: '',
    };

    this.setPeriodo = this.setPeriodo.bind(this);
    this.setStatusLogistico = this.setStatusLogistico.bind(this);
    this.setStatusFinanceiro = this.setStatusFinanceiro.bind(this);
    this.setTipoListagem = this.setTipoListagem.bind(this);
    this.setMostraEntregues = this.setMostraEntregues.bind(this);
    sessionStorage.setItem('idTipoListagemSelecionada', 1);
  }

  setPeriodo(event) {
    this.setState({ idPeriodoSelecionado: event.target.value });
  }

  setStatusFinanceiro(event) {
    this.setState({ idStatusFinanceiroSelecionado: event.target.value });
  }

  setStatusLogistico(event) {
    this.setState({ idStatusLogisticoSelecionado: event.target.value });
  }

  setMostraEntregues(event) {
    this.setState({ idMostraEntregues: event.target.value });
  }

  setTipoListagem(event) {
    this.setState({ idTipoListagemSelecionada: event.target.value });
    sessionStorage.setItem('idTipoListagemSelecionada', event.target.value);
    //PubSub.publish('atualiza-lista-pedidos-2');
    //PubSub.publish('atualiza-lista-pedidos');
  }

  componentDidMount() {
    PubSub.subscribe(
      'login-efetuado',
      function (p1, objPessoa) {
        if (objPessoa.Id > 0) {
          this.setState({ objPessoa: objPessoa });
          console.log(
            'pubsub funcionou na pagina de consulta de pedidos: ' +
            objPessoa.RazaoSocialNome,
          );
        } else {
          window.location.replace('/');
        }
      }.bind(this),
    );
  }

  submitBusca(event) {
    if (sessionStorage.WebConsultaPedidos === 'true') {
      event.preventDefault();
      if (sessionStorage.idTipoListagemSelecionada === "1") {
        PubSub.publish('atualiza-lista-pedidos-2'); //atualiza-lista-pedidos-itens-2
      }
      else {
        PubSub.publish('atualiza-lista-pedidos-itens-2'); //
      }

    } else {
      alert(
        'A CONSULTA DE PEDIDOS NÃO ESTÁ DISPONÍVEL. ENTRE EM CONTATO COM O [SAC] PARA MAIS INFORMAÇÕES',
      );
    }
  }

  render() {

    return (
      <div className="card" style={{ margin: '10px 0px 10px 0px' }}>
        <div className="container-fluid" style={{ margin: '15px 0px 5px 0px' }}>
          <h4 align="center">CONSULTA DE PEDIDOS</h4>
        </div>

        <div className="container-fluid" style={{ margin: '10px 0px 10px 0px' }} >
          <form onSubmit={this.submitBusca}>
            <div className="form-row">
              <h6 align="center"> </h6>
            </div>

            <div className="form-row">
              <label>
                Qual período?
                <div
                  className="form-group col-md2"
                  data-toggle="tooltip"
                  title="Exibir pedidos emitidos no(s) último(s)..."
                >
                  <select
                    id="periodo"
                    className="form-control"
                    value={this.state.idPeriodoSelecionado}
                    onChange={this.setPeriodo}
                  >
                    {this.state.listaPeriodo.map((item_c, i) => (
                      <option key={i} value={item_c.Id}>
                        {item_c.Opcao}
                      </option>
                    ))}
                  </select>
                </div>
              </label>

              <div
                className="form-group col-md-2 "
                data-toggle="tooltip"
                title="Filtrar pela situação do pagamento..."
              >
                <label>
                  Status Financeiro
                  <select
                    id="financeiro"
                    className="form-control"
                    value={this.state.idStatusFinanceiroSelecionado}
                    onChange={this.setStatusFinanceiro}
                  >
                    {this.state.listaStatusFinanceiro.map((item_t, i) => (
                      <option key={i} value={item_t.Id}>
                        {item_t.Opcao}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div
                className="form-group col-md-2"
                data-toggle="tooltip"
                title="Filtrar pela situação do pedido..."
              >
                <label>
                  Status Logístico
                  <select
                    id="logistico"
                    className="form-control"
                    value={this.state.idStatusLogisticoSelecionado}
                    onChange={this.setStatusLogistico}
                  >
                    {this.state.listaStatusLogistico.map((item_s, i) => (
                      <option key={i} value={item_s.Id}>
                        {item_s.Opcao}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div
                className="form-group col-md-2"
                data-toggle="tooltip"
                title="Mostrar pedidos que já foram entregues/enviados"
              >
                <label>
                  Mostrar finalizados?
                  <select
                    id="mostraEntregues"
                    className="form-control"
                    value={this.state.idMostraEntregues}
                    onChange={this.setMostraEntregues}
                  >
                    {this.state.listaMostraEntregues.map((item_m, i) => (
                      <option key={i} value={item_m.Id}>
                        {item_m.Opcao}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div
                className="form-group col-md-2"
                data-toggle="tooltip"
                title="Formato da listagem"
              >
                <label>
                  Formato da lista
                  <select
                    id="tipoLista"
                    className="form-control"
                    value={sessionStorage.idTipoListagemSelecionada}
                    onChange={this.setTipoListagem}
                  >
                    {this.state.listaTipoListagem.map((item_m, i) => (
                      <option key={i} value={item_m.Id}>
                        {item_m.Opcao}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <label>
                .
                <div className="form-group col-md-2 align-bottom">
                  <button
                    type="submit"
                    className="btn btn-primary align-bottom"
                  >
                    LISTAR
                  </button>
                </div>
              </label>
            </div>
          </form>

          {(() => {
            if (sessionStorage.idTipoListagemSelecionada === '1') {

              return (

                <div className="container-fluid" style={{ margin: '15px 0px 5px 0px' }}>
                  <ListaPedidos2
                    idPeriodoSelecionado={this.state.idPeriodoSelecionado}
                    idStatusFinanceiroSelecionado={this.state.idStatusFinanceiroSelecionado}
                    idStatusLogisticoSelecionado={this.state.idStatusLogisticoSelecionado}
                    idTipoListagemSelecionada={sessionStorage.idTipoListagemSelecionada}
                    idMostraEntregues={this.state.idMostraEntregues}
                  />
                </div>
              );
            }
            else if (sessionStorage.idTipoListagemSelecionada === '2') {

              return (

                <div className="container-fluid" style={{ margin: '15px 0px 5px 0px' }}>
                  <ListaPedidosItens2
                    idPeriodoSelecionado={this.state.idPeriodoSelecionado}
                    idStatusFinanceiroSelecionado={this.state.idStatusFinanceiroSelecionado}
                    idStatusLogisticoSelecionado={this.state.idStatusLogisticoSelecionado}
                    idTipoListagemSelecionada={sessionStorage.idTipoListagemSelecionada}
                    idMostraEntregues={this.state.idMostraEntregues}
                  />
                </div>
              );
            }
          })()}






        </div>
      </div>
    );
  }
}

export default PageConsultaPedidos2;
