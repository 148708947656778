import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import iconProdutos from '../img/icon_produtos.png';
import iconPedidos from '../img/icon_pedidos.png';
import PubSub from 'pubsub-js';

class CardSelection extends Component {
  constructor(props) {
    super(props);

    this.state = { objPessoa: '' };
  }

  componentDidMount() {
    PubSub.subscribe(
      'login-efetuado',
      function (p1, objPessoa) {
        this.setState({ objPessoa: objPessoa });
        console.log(
          'CARDSELECTOR RS = ' + this.state.objPessoa.RazaoSocialNome,
        );
        console.log('CARDSELECTOR ID = ' + this.state.objPessoa.Id);
      }.bind(this),
    );
  }

  render() {
    console.log('CARD objpessoa PROP RECEIVED: ' + this.state.objPessoa.Id);

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6" style={{ margin: '20px 0px 0px 0px' }}>

            {(() => {

              if (sessionStorage.WebConsultaPedidos === 'true') {
                return (
                  <div className="card">
                    <img
                      src={iconPedidos}
                      className="card-img-top rounded mx-auto d-block"
                      style={{ paddingTop: '15px', width: '80px' }}
                      alt=""
                    />
                    <div className="card-body">
                      <div className="card-title" style={{ textAlign: 'center' }}>
                        {<h5>PEDIDOS</h5>}{' '}
                      </div>
                      <div className="card-text" style={{ textAlign: 'center' }}>
                        {' '}
                        {
                          <p>
                            Consulte o Status dos seus <br />
                      últimos pedidos.
                    </p>
                        }{' '}
                      </div>
                      <Link
                        to="/pedidos"
                        //params={{ objPessoa: this.setState.objPessoa }}
                        className="align-self-end btn btn-sm btn-block btn-secondary"
                      >
                        {'ABRIR'}
                      </Link>
                    </div>
                  </div>

                );
              }
            })()}


          </div>


          <div className="col-md-6" style={{ margin: '20px 0px 0px 0px' }}>

            {(() => {

              if (sessionStorage.WebConsultaProdutos === 'true') {
                return (
                  <div className="card">
                    <img
                      src={iconProdutos}
                      className="card-img-top rounded mx-auto d-block"
                      style={{ paddingTop: '15px', width: '80px' }}
                      alt=""
                    />
                    <div className="card-body">
                      <div className="card-title" style={{ textAlign: 'center' }}>
                        {<h5>PRODUTOS</h5>}{' '}
                      </div>
                      <div className="card-text" style={{ textAlign: 'center' }}>
                        {' '}
                        {
                          <p>
                            Consulte o PREÇO e a DISPONIBILIDADE
                      <br /> de produtos em estoque.
                    </p>
                        }{' '}
                      </div>
                      <Link
                        to="/produtos"
                        //params={{ objPessoa: this.setState.objPessoa }}
                        className="align-self-end btn btn-sm btn-block btn-secondary"
                      >
                        {'ABRIR'}
                      </Link>
                    </div>
                  </div>


                );
              }
            })()}

          </div>
        </div>
      </div>
    );
  }
}

export default CardSelection;
