// import './App.css';
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import NavBar from './ui/NavBar';
import FormLogin from './ui/FormLogin';
import CardSelection from './ui/CardSelection';
import PageProdutos from './ui/PageProdutos';
import PageConsultaPedidos from './ui/PageConsultaPedidos';
import PageConsultaPedidos2 from './ui/PageConsultaPedidos2';
//import PageConsultaPedidos3 from './ui/PageConsultaPedidos3';

class App extends Component {
  render() {
    return (
      <div className="container-fluid">
        <NavBar />
        <FormLogin />

        <Switch>
          <Route path="/" exact={true} component={CardSelection} />
          {/*<Route path="/produtos" exact={true} component={PageProdutos} />*/}
          <Route
            path="/produtos2"
            exact={true}
            render={(props) => <PageProdutos {...props} />}
          />
          <Route path="/pedidos" component={PageConsultaPedidos} />
          <Route path="/pedidos2" component={PageConsultaPedidos2} />
          <Route path="/produtos" component={PageProdutos} />
          <Route component={CardSelection} />
        </Switch>
      </div>
    );
  }
}

export default App;
